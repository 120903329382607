input[type="file"] {
  display: none;
}

.upload-btn {
  background-color: #fb8d03;
  color: #fff;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
  box-shadow: none;
  border: none;
}
.upload-btn:disabled{
  background-color: #f0d4b3;
  cursor:default;
  color:rgb(134, 134, 134);
}
.upload-label{
  display: flex;
  align-items: start;
  width:100%;
  margin-bottom: 10px;
  justify-content: space-between;
}
.inv-btn{
  margin:0px 5px;
}

.upload-btn:disabled:hover{
  background-color: #f0d4b3;
  color:rgb(134, 134, 134);
}
.upload-btn:hover {
  background-color: #fb8d03;
}

.file-name {
  margin-left: 10px;
  font-size:18px;
}